<template>
<div id="page" class="new-apply-for-expense-summary">
  <el-row>
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-form-item prop="t_project_id" label="所属项目">
        <el-select v-model="form.t_project_id">
          <el-option v-for="(item, index) in projectOption" :key="index" :label="item.txt_project_name" :value="item.t_project_id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </el-row>
  <el-table :data="table.bigData" @row-dblclick="bigTableSelect" v-loading="table.loading" show-summary header-cell-class-name="table-cell">
    <el-table-column prop="createDate" label="提交日期" align="center" />
    <el-table-column prop="cde" label="CDE" align="center" />
    <el-table-column prop="pro_code" label="项目编码" align="center" />
    <el-table-column prop="pro_name" label="项目名称" align="center" />
    <el-table-column prop="review" label="访视" align="center" />
    <el-table-column prop="crc" label="CRC" align="center" />
    <el-table-column prop="code" label="受试者筛选号" align="center" />
    <el-table-column prop="sname" label="受试者简称" align="center" />
    <el-table-column prop="price" label="提交金额" align="center" />
    <el-table-column prop="detail" label="明细" align="center">
      <template #default="scope">
        <el-tooltip class="box-item" effect="dark" :content="scope.row.detail" placement="top">
          {{ scope.row.detail.slice(0, 5) + "..." }}
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
  <div class="esi-summary-part">
    <div class="esisp-left">
      <el-row>
        <el-button>汇总数据</el-button>
        <el-button>清空选中</el-button>
      </el-row>
      <el-table :data="table.selectedData" @row-dblclick="selectedTableSelect" v-loading="table.loading" show-summary show-overflow-tooltip header-cell-class-name="table-cell">
        <el-table-column prop="createDate" label="提交日期" align="center" />
        <el-table-column prop="cde" label="CDE" align="center" />
        <el-table-column prop="pro_code" label="项目编码" align="center" />
        <el-table-column prop="pro_name" label="项目名称" align="center" />
        <el-table-column prop="review" label="访视" align="center" />
        <el-table-column prop="crc" label="CRC" align="center" />
        <el-table-column prop="code" label="受试者筛选号" align="center" />
        <el-table-column prop="sname" label="受试者简称" align="center" />
        <el-table-column prop="price" label="提交金额" align="center" />
        <el-table-column prop="detail" label="明细" align="center" />
      </el-table>
    </div>
  </div>
</div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue';
import { getProjectList } from "api/apis.js";
import { ElMessage } from 'element-plus';
export default {
  setup() {
    const state = reactive({
      form: {
        t_project_id: null,
        page: 1,
        limit: 10
      },
      projectOption: [],
      table: {
        bigData: [],
        loading: false,
        selectedData: []
      },
    })

    onMounted(() => {
      getProjectOption();
      getTableList();
    })

    // 被选中的列表 双击事件
    const selectedTableSelect = row => {
      // 找出 被点击行 在数组中的索引
      let selectedIndex = state.table.selectedData.findIndex(t => t.id == row.id);
      state.table.bigData.push(row);
      // 从 被选中数组 中 删除 被双击行
      state.table.selectedData.splice(selectedIndex, 1);
    };

    // 大列表 表格双击 事件
    const bigTableSelect = row => {
      // 找出 被双击行 在数组中的索引
      let selectedIndex = state.table.bigData.findIndex(t => t.id == row.id);
      // 将 被双击行 插入至 被选中数组 中
      state.table.selectedData.push(state.table.bigData[selectedIndex]);
      // 在 大列表 中 删除 被选中行
      state.table.bigData.splice(selectedIndex, 1);
    }

    // 获取列表数据
    const getTableList = () => {
      state.table.loading = true;
      state.table.bigData = [
        { id: 1, createDate: "2023-10-10", cde: "CTR11", pro_code: "GH21-CR1", pro_name: "GH21胶囊", review: "导入期-V1", crc: "A", crc_id: 1, code: "CS001", sname: "CXC", price: "1", detail: "交通费：1，住宿费：￥200" },
        { id: 2, createDate: "2023-11-12", cde: "CTR32", pro_code: "GH21-CR2", pro_name: "GH21胶囊", review: "治疗期-C1-V1", crc: "A", crc_id: 2, code: "CS002", sname: "AAA", price: "10", detail: "交通费：1，住宿费：￥200" },
        { id: 3, createDate: "2023-01-10", cde: "CTR43", pro_code: "GH21-CR3", pro_name: "GH21胶囊", review: "导入期-V1", crc: "A", crc_id: 2, code: "CS002", sname: "AAA", price: "14", detail: "交通费：1，住宿费：￥200" },
        { id: 4, createDate: "2023-08-21", cde: "CTR13", pro_code: "GH21-CR4", pro_name: "GH21胶囊", review: "导入期-V1", crc: "A", crc_id: 3, code: "CS003", sname: "BBB", price: "13", detail: "交通费：1，住宿费：￥200" },
        { id: 5, createDate: "2023-10-32", cde: "CTR25", pro_code: "GH21-CR5", pro_name: "GH21胶囊", review: "治疗期-C1-V2", crc: "A", crc_id: 4, code: "CS004", sname: "CCC", price: "12", detail: "交通费：1，住宿费：￥200" },
        { id: 6, createDate: "2023-08-04", cde: "CTR16", pro_code: "GH21-CR6", pro_name: "GH21胶囊", review: "导入期-V1", crc: "A", crc_id: 5, code: "CS005", sname: "DDD", price: "21", detail: "交通费：1，住宿费：￥200" },
        { id: 7, createDate: "2023-11-10", cde: "CTR24", pro_code: "GH21-CR7", pro_name: "GH21胶囊", review: "导入期-V1", crc: "A", crc_id: 5, code: "CS005", sname: "DDD", price: "81", detail: "交通费：1，住宿费：￥200" },
      ]
      state.table.loading = false;
    };

    // 获取项目 - 选项
    const getProjectOption = () => {
      getProjectList({ pageSize: 999, pages: 1 }).then(response => {
        if (response.code == 200) {
          state.projectOption = response.data;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      })
    };
    return {
      ...toRefs(state),
      getProjectOption,
      getTableList,
      bigTableSelect,
      selectedTableSelect
    }
  }
}
</script>

<style lang="scss" scoped>
.new-apply-for-expense-summary {
  box-sizing: border-box;

  /deep/ .el-table {
    .el-table__row {
      cursor: pointer;
    }
  }
}
</style>
