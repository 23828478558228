<template>
<el-tabs type="border-card" id="page" class="expense-summary-index">
  <el-tab-pane label="新建付款申请">
    <new-apply />
  </el-tab-pane>
  <el-tab-pane label="历史申请">Role</el-tab-pane>
</el-tabs>
</template>

<script>
// 费用发放 - 费用汇总/发放
import { reactive, toRefs } from 'vue';
import NewApply from "./comp/newApply.vue";
export default {
  components: {
    NewApply,
  },
  setup() {
    const state = reactive({})

    return {
      ...toRefs(state),
    }
  }
}
</script>

<style lang="scss" scoped>
.expense-summary-index {
  box-sizing: border-box;

  /deep/ .el-tabs__item {
    &.is-active {
      font-weight: bold;
      color: #8a88c3;
    }

    &:hover {
      color: #1f225f !important;
    }
  }
}
</style>
